@import url('https://fonts.googleapis.com/css?family=Galada|Leckerli+One|PT+Mono&display=swap');

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-weight: 200;
  letter-spacing: 2px;
  background: url('./assets/bg.png') no-repeat center center;
  background-size: cover;
  background-color: #1c1c1c;
}


.container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  padding: 25px 15px;
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

h1 {
  font-family: 'Leckerli One', cursive;
  font-size: 9.125vw;
  color: #ffd200;
  text-shadow: 2px 2px #FFF;
  margin: 20px 0 0;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 1s;
}

h3 {
  margin: 0;
  color: #000;
  font-family: 'PT Mono', monospace;
  display: inline-flex;
  font-size: 4vw;
  text-shadow: 4px 2px #ffd200;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 2s;
}

.svg {
  margin-right: 8px;
}

a.links {
  color: #fff;
}

a.links:hover {
  color: #ffd200;
}

.icons {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.status {
  display: flex;
  justify-content: center;
}

.status p {
  margin: 0;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: bold;
  background-color: rgba(0,0,0,.6);
  padding: 3px;
}

.pulse {
  display: inline-flex;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #43ec1d;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(67, 236, 29, 0.4);
  animation: pulse 2s infinite;
  margin-right: 5px;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(67, 236, 29, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(67, 236, 29, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(67, 236, 29, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(67, 236, 29, 0.4);
    box-shadow: 0 0 0 0 rgba(67, 236, 29, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(67, 236, 29, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(67, 236, 29, 0);
      box-shadow: 0 0 0 0 rgba(67, 236, 29, 0);
  }
}
